import { parseISO, format } from 'date-fns'
import { pillColors } from '../../lib/colors'
import { getInitials } from '../../lib/dealUtils'

interface Option {
  label: string
  value: string | number | boolean
}

export function formatCurrencyValue(value: number | string): string {
  if (typeof value === 'string') {
    value = parseFloat(value)
    if (isNaN(value)) {
      throw new Error('Invalid input: not a number')
    }
  }

  return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export function formatPercentageValue(value: number | string): string {
  if (typeof value === 'string') {
    value = parseFloat(value)
    if (isNaN(value)) {
      throw new Error('Invalid input: not a number')
    }
  }

  return new Intl.NumberFormat('en-US', {
    style: 'percent',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value / 100)
}

export function formatPhoneNumber(str: string): string {
  let cleaned = ('' + str).replace(/\D/g, '')
  let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)

  if (match) {
    let intlCode = match[1] ? '+1 ' : ''
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
  }

  return str
}
export function removeCurrencyFormatting(str: any): string {
  return String(str).replace(/[\$,]/g, '').replace(/\.00$/, '')
}
export function removePercentageSign(str: any): string {
  return String(str).replace(/%/g, '')
}

export function removePhoneFormatting(str: any) {
  return String(str).replace(/[\(\)-\s]/g, '')
}

export function getDateFormat(date: any) {
  const currentYear = new Date().getFullYear()
  const selectedYear = new Date(date).getFullYear()
  return selectedYear === currentYear ? 'MMM dd' : 'MMM dd, yyyy'
}

export function formatDateTime(datetimeStr: any) {
  if (!datetimeStr) {
    return datetimeStr
  }
  const date = parseISO(datetimeStr)
  return format(date, 'yyyy-MM-dd HH:mm:ss')
}
export function formatDateToYYYYMMDD(date: any) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const formattedMonth = month < 10 ? `0${month}` : month
  const formattedDay = day < 10 ? `0${day}` : day

  return `${year}-${formattedMonth}-${formattedDay}`
}
export function matchesYYYYMMDDPattern(str: string) {
  const pattern = /^\d{4}-\d{2}-\d{2}$/
  return pattern.test(str)
}

export function parseLocalDate(dateString: string) {
  if (!dateString) return new Date()
  const [year, month, day] = dateString.split('-').map(val => parseInt(val, 10))
  return new Date(year, month - 1, day)
}

export function sortFields(fields: any[] | undefined) {
  return fields
    ? [...fields].sort((a: any, b: any) => {
        const nameA = a.label.toUpperCase()
        const nameB = b.label.toUpperCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    : undefined
}

export const getPicklistSelectStyles = (
  color = 'black',
  backgroundColor = 'white',
  minWidth = '170px',
  maxWidth = '170px',
  isMultiSelect = false,
) => {
  return {
    control: (styles: any, state: any) => ({
      ...styles,
      background: backgroundColor,
      border: 'none',
      minHeight: '27px',
      maxHeight: '27px',
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      borderRadius: '15px',
      cursor: 'pointer',
      _focus: {
        outline: 'none !important',
        boxShadow: 'none !important',
        border: 'none',
      },
      fontSize: '0.8rem',
      minWidth: minWidth,
      maxWidth: maxWidth,
    }),
    multiValueLabel: (styles: any, state: any) => ({
      ...styles,
      fontSize: '10px', // Setting font size for selected chips
    }),
    multiValue: (styles: any, state: any) => ({
      ...styles,
      minWidth: 'auto',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 4px 12px 0px',
      borderRadius: '4px',
      fontSize: '0.8rem',
      backgroundColor: 'white',
      overflow: 'hidden',
      marginTop: '15px',
      minWidth: minWidth,
      maxWidth: maxWidth,
      width: minWidth,
      marginLeft: '1px',
    }),
    option: (styles: any, { isFocused, isSelected }: any) => {
      return {
        ...styles,
        color: 'black',
        maxWidth: '100%',
        backgroundColor: isSelected
          ? 'white'
          : isFocused
          ? 'rgb(248, 248, 248)'
          : 'white',
        _hover: {
          backgroundColor: 'rgb(248, 248, 248)',
        },
        width: 'calc(100% - 14px)',
        marginLeft: '7px',
        marginRight: '7px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        fontSize: '11px',
      }
    },
    inputContainer: (styles: any, state: any) => ({
      ...styles,
      color: 'black',
      '&:focus': {
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
      },
    }),
    singleValue: (styles: any, state: any) => ({
      ...styles,
      color: color,
      fontWeight: '500',
      textAlign: 'center',
      width: '100%',
      fontSize: '11px',
    }),
    container: (styles: any) => ({
      ...styles,
      height: 'fit-content',
      width: 'max-content',
      minHeight: '32px',
      borderRadius: '0px',
      marginLeft: '0.5px',
      marginRight: '0.5px',
      '&:focus': {
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
      },
    }),
    valueContainer: (styles: any, state: any) => ({
      ...styles,
      padding: 0,
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '0.8rem',
      color: 'black',
      '&:focus': {
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
      },
      ...(isMultiSelect && {
        display: 'flex',
        flexWrap: 'nowrap', // Prevent wrapping of the content
        overflowX: 'auto', // Enable horizontal scrolling

        whiteSpace: 'nowrap', // Prevent text from wrapping
        '&::-webkit-scrollbar': {
          height: '5px', // Optional: control the scrollbar height for horizontal scroll
        },
      }),
    }),
    input: (styles: any) => ({
      ...styles,
    }),
  }
}

export const getColorIndex = (label: string, count: number): number => {
  switch (label) {
    case 'Sent':
      return 0
    case 'Cold':
      return 2
    case 'Hot':
      return 1
    case 'Warm':
      return 8
    case 'Qualification':
      return 9
    case 'Overdue':
      return 9
    case 'Closed Lost':
      return 1
    case 'Void':
      return 1
    case 'Closed Won':
      return 8
    case 'Paid':
      return 8
    default:
      return count % pillColors.length
  }
}

export function formatDateTimePretty(datetime: string): string {
  if (!isNaN(Date.parse(datetime))) {
    const date = new Date(datetime)
    return format(date, 'h:mma MMM dd, yyyy')
  }

  return datetime
}

export function formatAddress(address: any): string {
  if (!address) {
    return ''
  }

  let formattedAddress = ''
  if (!!address.street) {
    formattedAddress += address.street
  }
  if (!!address.city) {
    formattedAddress += ' ' + address.city
  }
  if (!!address.state) {
    formattedAddress += ' ' + address.state
  }
  if (!!address.postalCode) {
    formattedAddress += ' ' + address.postalCode
  }
  return formattedAddress
}

export function getSelectOptions(
  field: any,
  referenceMap: { [key: string]: Array<Option> },
) {
  if (field?.picklist_values.length > 0) {
    return field?.picklist_values.map(
      (picklist_value: { label: any; value: any }) => ({
        value: picklist_value.value,
        label: picklist_value.label,
      }),
    )
  }

  if (field.type === 'boolean') {
    return [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ]
  }

  if (field.type === 'reference') {
    return referenceMap[field.reference_to] ?? []
  }

  return []
}

export const getColumnWidth = (fieldType: string, fieldName: string) => {
  switch (fieldType) {
    case 'date':
      return '225px'
    case 'currency':
      return '275px'
    case 'percent':
      return '250px'
  }
  switch (fieldName) {
    case 'StageName':
      return '305px'
  }

  return '200px'
}

export function getUpdateErrorMessage(
  fieldName: string,
  value: any,
  fieldType: string,
  responseMessage: string,
) {
  if (
    isNaN(value) &&
    (fieldType === 'currency' ||
      fieldType === 'number' ||
      fieldType === 'percent')
  ) {
    return `Could not update ${fieldName} with value "${value}". Value must be a number`
  }

  return `Could not update ${fieldName} with value "${value}". ${responseMessage?.replace(
    /^"|"$/g,
    '',
  )}`
}

export const isNameCell = (cellId: any) => {
  return cellId === 'Name'
}

export const recordNameInitials = (name: string) => {
  return getInitials(name).substring(0, 3)
}

const compareValues = (
  first: any,
  second: any,
  type: any,
  operation: string,
) => {
  if (
    type === 'currency' ||
    type === 'number' ||
    type === 'percent' ||
    type === 'double' ||
    type === 'int'
  ) {
    first = parseFloat(first)
    second = parseFloat(second)

    if (isNaN(first) || isNaN(second)) {
      return false
    }

    if (Number.isInteger(first)) {
      first = parseInt(first)
    }

    if (Number.isInteger(second)) {
      second = parseInt(second)
    }
  } else {
    first = first.toString()
    second = second.toString()
  }

  switch (operation) {
    case 'equals':
      return first == second
    case 'notEqual':
      return first != second
    case 'startsWith':
      return String(first).startsWith(String(second))
    case 'lessThan':
      return first < second
    case 'greaterThan':
      return first > second
    case 'lessOrEqual':
      return first <= second
    case 'greaterOrEqual':
      return first >= second
    case 'notContain':
      return !String(first).includes(String(second))
    case 'contains':
      return String(first).includes(String(second))
    /**
     * Not supported yet - supposedly only for multi-select picklist fields 
     * Not supported yet - between (dashboard filters), within (geolocation custom field)
    case 'includes':
      return 'IN'
    case 'excludes':
      return 'NOT IN'
    */
    default:
      return false
  }
}

const operationTranslator = (operation: string) => {
  switch (operation) {
    case 'equals':
      return '='
    case 'notEqual':
      return '!='
    case 'startsWith':
      return 'LIKE'
    case 'lessThan':
      return '<'
    case 'greaterThan':
      return '>'
    case 'lessOrEqual':
      return '<='
    case 'greaterOrEqual':
      return '>='
    case 'notContain':
      return 'NOT IN'
    case 'contains':
      return 'IN'
    /**
     * Not supported yet - supposedly only for multi-select picklist fields 
     * Not supported yet - between (dashboard filters), within (geolocation custom field)
    case 'includes':
      return 'IN'
    case 'excludes':
      return 'NOT IN'
    */
    default:
      return ''
  }
}

const generateFinalQuerySegment = (
  querySegments: string[],
  filterLogic: string,
) => {
  return filterLogic.replace(/\d+/g, match => {
    let index = parseInt(match, 10) - 1
    return querySegments[index] ? querySegments[index] : match
  })
}

export const isCustom = (name: any) => {
  return name.includes('__c')
}

export const generateLookupFilterQL = (
  lookupFilters: any,
  fieldValues: any,
) => {
  let queryString = ''
  let queries: string[] = []

  if (Object.keys(lookupFilters).length > 0 && !!lookupFilters['active']) {
    const filter_logic = lookupFilters.boolean_filter ?? ''

    if (Array.isArray(lookupFilters.lookup_filter)) {
      lookupFilters.lookup_filter.forEach((lookupFilter: any) => {
        if (operationTranslator(lookupFilter.operation) === '') {
          return
        }
        const lhs_raw_field = lookupFilter.field
        const rhs_raw_field = lookupFilter.value_field ?? lookupFilter.value

        let lhs_field = ''
        let rhs_field = ''
        if (lookupFilter.value_field) {
          // If lhs has $Source and we have value_field we swap with rhs later so value is always on right side
          let extracted_cell_value =
            fieldValues[lhs_raw_field.substring(lhs_raw_field.indexOf('.') + 1)]
          let lhs_value =
            typeof extracted_cell_value === 'string'
              ? `'${extracted_cell_value}'`
              : extracted_cell_value
          lhs_field = lhs_raw_field.includes('$Source')
            ? `${lhs_value}`
            : lhs_raw_field.substring(lhs_raw_field.indexOf('.') + 1)
        } else {
          if (lhs_raw_field.includes('$Source')) {
            let extracted_cell_value =
              fieldValues[
                lhs_raw_field.substring(lhs_raw_field.indexOf('.') + 1)
              ]
            let comp_val = rhs_raw_field
            if (
              compareValues(
                extracted_cell_value,
                comp_val,
                lookupFilter.type,
                lookupFilter.operation,
              )
            ) {
              // This always evaluates to true
              queries.push('Id != null')
              return
            } else {
              // This always evaluates to false
              queries.push('Id = null')
              return
            }
          } else {
            lhs_field = lhs_raw_field.substring(lhs_raw_field.indexOf('.') + 1)
          }
        }

        const operation = operationTranslator(lookupFilter.operation)

        if (lookupFilter.value_field) {
          let extracted_cell_value =
            fieldValues[rhs_raw_field.substring(rhs_raw_field.indexOf('.') + 1)]
          let rhs_value =
            typeof extracted_cell_value === 'string'
              ? `'${extracted_cell_value}'`
              : extracted_cell_value
          rhs_field = rhs_raw_field.includes('$Source')
            ? `${rhs_value}`
            : rhs_raw_field.substring(rhs_raw_field.indexOf('.') + 1)
        } else {
          rhs_field =
            lookupFilter.type === 'string' ||
            lookupFilter.type === 'reference' ||
            lookupFilter.type === 'id' ||
            lookupFilter.type === 'picklist' ||
            lookupFilter.type === 'multipicklist' ||
            lookupFilter.type === 'text' ||
            lookupFilter.type === 'textarea'
              ? `'${rhs_raw_field}'`
              : `${rhs_raw_field}`
        }

        if (lhs_raw_field.includes('$Source')) {
          const temp = lhs_field
          lhs_field = rhs_field
          rhs_field = temp
        }

        if (lookupFilter.operation === 'startsWith') {
          queries.push(
            `${lhs_field} ${operation} ${rhs_field.substring(
              0,
              rhs_field.length - 1,
            )}%'`,
          )
        } else if (
          lookupFilter.operation === 'contains' ||
          lookupFilter.operation === 'notContain'
        ) {
          queries.push(`${lhs_field} ${operation} (${rhs_field})`)
        } else {
          queries.push(`${lhs_field} ${operation} ${rhs_field}`)
        }
      })

      if (filter_logic === '') {
        // In case there is no filter logic and multiple filters (they will always be separated by `AND`)
        queryString = queries.join(' AND ')
      } else {
        queryString = generateFinalQuerySegment(queries, filter_logic)
      }

      queryString = ' AND (' + queryString + ')'
    } else {
      // If there's only one lookup filter it is not iterable
      const lhs_raw_field = lookupFilters.lookup_filter.field
      const rhs_raw_field =
        lookupFilters.lookup_filter.value_field ??
        lookupFilters.lookup_filter.value

      let lhs_field = ''
      let rhs_field = ''
      if (lookupFilters.lookup_filter.value_field) {
        // If lhs has $Source and we have value_field we swap with rhs later so value is always on right side
        let extracted_cell_value =
          fieldValues[lhs_raw_field.substring(lhs_raw_field.indexOf('.') + 1)]
        let lhs_value =
          typeof extracted_cell_value === 'string'
            ? `'${extracted_cell_value}'`
            : extracted_cell_value
        lhs_field = lhs_raw_field.includes('$Source')
          ? `${lhs_value}`
          : lhs_raw_field.substring(lhs_raw_field.indexOf('.') + 1)
      } else {
        if (lhs_raw_field.includes('$Source')) {
          let extracted_cell_value =
            fieldValues[lhs_raw_field.substring(lhs_raw_field.indexOf('.') + 1)]
          let comp_val = rhs_raw_field
          // We only have one lookup filter so we can just set query string and return
          if (
            compareValues(
              extracted_cell_value,
              comp_val,
              lookupFilters.lookup_filter.type,
              lookupFilters.lookup_filter.operation,
            )
          ) {
            queryString = ' AND Id != null'
            return queryString
          } else {
            queryString = ' AND Id = null'
            return queryString
          }
        } else {
          lhs_field = lhs_raw_field.substring(lhs_raw_field.indexOf('.') + 1)
        }
      }

      const operation = operationTranslator(
        lookupFilters.lookup_filter.operation,
      )

      if (operation === '') {
        return ''
      }

      if (lookupFilters.lookup_filter.value_field) {
        let extracted_cell_value =
          fieldValues[rhs_raw_field.substring(rhs_raw_field.indexOf('.') + 1)]

        let rhs_value =
          typeof extracted_cell_value === 'string'
            ? `'${extracted_cell_value}'`
            : extracted_cell_value
        rhs_field = rhs_raw_field.includes('$Source')
          ? `${rhs_value}`
          : rhs_raw_field.substring(rhs_raw_field.indexOf('.') + 1)
      } else {
        rhs_field =
          lookupFilters.lookup_filter.type === 'string' ||
          lookupFilters.lookup_filter.type === 'reference' ||
          lookupFilters.lookup_filter.type === 'id' ||
          lookupFilters.lookup_filter.type === 'picklist' ||
          lookupFilters.lookup_filter.type === 'multipicklist' ||
          lookupFilters.lookup_filter.type === 'text' ||
          lookupFilters.lookup_filter.type === 'textarea'
            ? `'${rhs_raw_field}'`
            : `${rhs_raw_field}`
      }

      if (lhs_raw_field.includes('$Source')) {
        const temp = lhs_field
        lhs_field = rhs_field
        rhs_field = temp
      }

      if (lookupFilters.lookup_filter.operation === 'startsWith') {
        queries.push(
          `${lhs_field} ${operation} ${rhs_field.substring(
            0,
            rhs_field.length - 1,
          )}%'`,
        )
      } else if (
        lookupFilters.lookup_filter.operation === 'contains' ||
        lookupFilters.lookup_filter.operation === 'notContain'
      ) {
        queries.push(`${lhs_field} ${operation} (${rhs_field})`)
      } else {
        queries.push(`${lhs_field} ${operation} ${rhs_field}`)
      }

      // One filter; no filter logic
      queryString = ' AND ' + queries[0]
    }
  }

  return queryString
}
