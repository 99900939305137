import { FieldConfig, useFormikContext } from 'formik'
import {
  Props,
  createFilter,
  Select as ChakraReactSelect,
  components,
} from 'chakra-react-select'
import React from 'react'
import { FixedSizeList as List } from 'react-window'
import get from 'lodash/get'
import { IoIosArrowDown } from 'react-icons/io'

type SelectProps = Props &
  FieldConfig & {
    creatable?: boolean
    maxItems?: number
    noPadding?: boolean
    useVirtualizedRendering?: boolean
  }

export type SelectOption = {
  value: string | number
  label: string
}

export const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <div style={{ marginRight: '15px', marginTop: '1px' }}>
        <IoIosArrowDown size={20} color='#8D8D8D' cursor={'pointer'} />{' '}
      </div>
    </components.DropdownIndicator>
  )
}

export const baseStyles = ({
  color,
  backgroundColor,
  height,
  fontSize,
}: any) => {
  return {
    control: (styles: any) => ({
      ...styles,
      background: backgroundColor,
      border: '1px solid black',
      minHeight: height,
      maxHeight: height,
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      borderRadius: '6px',
      fontSize: fontSize,
      width: '100%',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 4px 12px 0px',
      borderRadius: '6px',
      fontSize: fontSize,
      backgroundColor: 'white',
      marginTop: fontSize,
      width: '100%',
      marginLeft: '1px',
      maxHeight: '300px',
      overflow: 'hidden',
    }),
    option: (styles: any, { isFocused, isSelected }: any) => {
      return {
        ...styles,
        maxWidth: '97%',
        backgroundColor: isSelected
          ? 'rgb(248, 248, 248)'
          : isFocused
          ? 'rgb(248, 248, 248)'
          : 'white',
        _hover: {
          backgroundColor: 'rgb(248, 248, 248)',
        },
        width: 'calc(100% - 14px)',
        marginLeft: '7px',
        marginRight: '7px',
        boxSizing: 'border-box',
        borderRadius: '4px',
        fontSize: '13px',
        color: '#646464',
        fontWeight: '500',
        height: '35px',
      }
    },
    inputContainer: (styles: any) => ({
      ...styles,
      color: 'black',
      width: '100%',
      fontSize: fontSize,
      paddingLeft: '16px',
      '&:focus': {
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
      },
    }),
    singleValue: (styles: any) => ({
      ...styles,
      color: color,
      width: '100%',
      fontSize: fontSize,
      paddingLeft: '16px',
    }),
    container: (styles: any) => ({
      ...styles,
      height: 'fit-content',
      width: '100%',
      minHeight: '32px',
      borderRadius: '0px',
      marginLeft: '0.5px',
      marginRight: '0.5px',
      '&:focus': {
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
      },
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      padding: 0,
      borderRadius: '4px',
      cursor: 'pointer',
      fontSize: '0.8rem',
      color: 'black',
      '&:focus': {
        outline: 'none',
        border: 'none',
        boxShadow: 'none',
      },
    }),
    input: (styles: any) => ({
      ...styles,
    }),
    placeholder: (styles: any) => ({
      ...styles,
      paddingLeft: '16px',
      fontSize: fontSize,
    }),
  }
}

export const Select = (props: any) => {
  const { useVirtualizedRendering, ...restProps } = props

  if (useVirtualizedRendering) {
    return <VirtualizedSelect {...restProps} />
  }

  return <RegularSelect {...restProps} />
}

const RegularSelect = (props: any) => {
  const { setFieldValue, values, setTouched } = useFormikContext()
  const { options, name, value, customOnChange } = props
  const currentValue =
    value !== undefined && !get(values, name) ? value : get(values, name)

  const singleValue = props.options
    ? props.options.find(
        (option: any) => (option as SelectOption).value === currentValue,
      )
    : ''

  const handleSelectChange = (option: any) => {
    // Update Formik field
    setFieldValue(name, option.value)
    setTouched({ [name]: true })

    // Call the custom onChange handler, if provided
    if (customOnChange) {
      customOnChange(option)
    }
  }

  return (
    <ChakraReactSelect
      options={props.options}
      chakraStyles={baseStyles({
        color: 'black',
        backgroundColor: 'white',
        height: props.height || '48px',
        fontSize: props.fontSize || '15px',
      })}
      isDisabled={props.isDisabled}
      escapeClearsValue={true}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
      }}
      placeholder={props.placeholder}
      name={props.name}
      filterOption={createFilter({ ignoreAccents: false })}
      value={singleValue}
      isOptionDisabled={option => {
        return option.disabled || false
      }}
      onChange={handleSelectChange}
      onBlur={props.onBlur}
    />
  )
}

const VirtualizedSelect = (props: any) => {
  const { setFieldValue, values, setTouched } = useFormikContext()
  const { options, name, value, customOnChange } = props

  const currentValue =
    value !== undefined && !get(values, name) ? value : get(values, name)

  const singleValue = props.options
    ? props.options.find(
        (option: any) => (option as SelectOption).value === currentValue,
      )
    : ''

  const handleSelectChange = (option: any) => {
    // Update Formik field
    setFieldValue(name, option.value)
    setTouched({ [name]: true })

    // Call the custom onChange handler, if provided
    if (customOnChange) {
      customOnChange(option)
    }
  }

  const ITEM_HEIGHT = 35
  const LIST_HEIGHT = 250

  const MenuList = ({ children }: any) => {
    return (
      <List
        height={LIST_HEIGHT}
        itemCount={children.length}
        itemSize={ITEM_HEIGHT}
        width='100%'
        overscanCount={5}
        style={{ overflowY: 'auto' }}
      >
        {({ index, style }) => (
          <div
            style={{
              ...style,
              height: ITEM_HEIGHT,
            }}
          >
            {children[index]}
          </div>
        )}
      </List>
    )
  }

  return (
    <ChakraReactSelect
      placeholder={props.placeholder}
      name={props.name}
      filterOption={createFilter({ ignoreAccents: false })}
      value={singleValue}
      isOptionDisabled={option => {
        return option.disabled || false
      }}
      onBlur={props.onBlur}
      options={props.options}
      onChange={handleSelectChange}
      chakraStyles={baseStyles({
        color: 'black',
        backgroundColor: 'white',
        height: props.height || '48px',
        fontSize: props.fontSize || '15px',
      })}
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator,
        MenuList,
      }}
    />
  )
}

export default Select
