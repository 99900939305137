import { BucketType } from './Types'

/**
 * Generates a cohort name string based on a given date and bucket type.
 * Handles both monthly and quarterly cohort formats.
 * 
 * @param date - The date string in 'YYYY-MM-DD' format from which to derive the cohort.
 * @param bucket - The bucket type ('month' or 'quarter') determining the cohort format.
 * @param fiscalYearStartMonth - The month (1-12) when the fiscal year starts, defaulting to January (1).
 * 
 * @returns The cohort name string:
 *   - For months: "YYYY-MM" (e.g., "2023-03")
 *   - For quarters: "YYYY-Q#" (e.g., "2023-Q1")
 *   - For unsupported buckets: throws an error
 */
export function cohortNameFromDate(date: string, bucket: BucketType, fiscalYearStartMonth = 1) {
  const dateObj = new Date(date)
  const calendarYear = dateObj.getUTCFullYear()
  const calendarMonth = dateObj.getUTCMonth() + 1  // 1-based month

  switch (bucket) {
    case 'month':
      return `${calendarYear}-${String(calendarMonth).padStart(2, '0')}`
    case 'quarter': {
      const fiscalStartMonth = fiscalYearStartMonth - 1
      
      // Adjust the fiscal year based on start month
      const fiscalYear = calendarMonth <= fiscalStartMonth 
        ? calendarYear - 1 
        : calendarYear

      // Adjust the month number to be relative to fiscal year start
      const fiscalMonth = (calendarMonth - fiscalStartMonth + 12) % 12
      return `${fiscalYear}-Q${Math.floor(fiscalMonth / 3) + 1}`
    }
    default:
      throw new Error(`Unsupported bucket type: ${bucket}. Expected 'month' or 'quarter'.`)
  }
}

// TODO: Deprecate this function. Replace use with cohortNameFromDate
export function cohortNameFromOpp(opportunity: any) {
  return cohortNameFromDate(opportunity.CreatedDate, 'month')
}

/**
 * Formats a cohort string into a human-readable format based on the bucket type.
 * Handles cohort strings generated by cohortNameFromDate().
 * @param cohort - The cohort string to format
 *   - For months: "2023-03" (YYYY-MM format, from cohortNameFromDate)
 *   - For quarters: "2023-Q1" (YYYY-Q# format, from cohortNameFromDate)
 * @param bucket - The bucket type ('month' or 'quarter'), matching cohortNameFromDate options
 * @returns The formatted cohort string
 *   - For months: "Mar '23"
 *   - For quarters: "FY23 Q1"
 *   - For invalid buckets: returns the original cohort string
 */
export function renderCohortName(cohort: string, bucket: string) {
  switch (bucket) {
    case 'month': {
      const date = new Date(`${cohort}-01 00:00:00`)
      const month = date.toLocaleString('default', { month: 'short' })
      const year = date.toLocaleString('default', { year: '2-digit' })
      return `${month} '${year}`
    }
    case 'quarter': {
      const [year, quarter] = cohort.split('-')
      const fiscalYear = year.slice(2)
      return `FY${fiscalYear} ${quarter}`
    }
    default:
      return cohort
  }
}

/**
 * Returns the number of days between two dates, ignoring the time component.
 * This will return a negative number if the end date is before the start date.
 * @param startDate - The start date in the format 'YYYY-MM-DD'.
 * @param endDate - The end date in the format 'YYYY-MM-DD'.
 * @returns The number of days between the two dates.
 */
export function daysBetweenDates(startDate: string, endDate: string) {
  const dt1 = new Date(startDate)
  const dt2 = new Date(endDate)

  const d1 = Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())
  const d2 = Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate())
  const ms = d2 - d1
  const days = ms / 1000 / 60 / 60 / 24
  return dt2 > dt1 ? Math.floor(days) : Math.ceil(days)
}
