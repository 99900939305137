import {
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'
import { tw } from '@minupalaniappan/brise'
import { Layout } from 'antd'
import { get } from 'lodash'
import router, { useRouter } from 'next/router'
import React, { useContext, useState } from 'react'
import { MdArrowBackIosNew } from 'react-icons/md'

import {
  MagnifyingGlassIcon,
  ChartBarIcon,
  ShareIcon,
  CommandLineIcon,
  WrenchIcon,
  ClipboardIcon,
  PencilSquareIcon,
  BellAlertIcon,
  ArrowTrendingUpIcon,
  EyeIcon,
  ChartBarSquareIcon,
  ArrowPathIcon,
  SquaresPlusIcon,
  IdentificationIcon,
  Square3Stack3DIcon,
  Cog6ToothIcon,
  ChatBubbleBottomCenterTextIcon,
  UserCircleIcon,
  DocumentMagnifyingGlassIcon,
  ChevronRightIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline'

import { Edit } from 'react-feather'

import { StateStore } from '../Store'
import Header from './Header'
import styled from 'styled-components'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { FLAGS } from '../../lib/flags'
import { availableCharts } from '../PipelineAnalytics/AvailableCharts'

const { Sider } = Layout

const StyledSider = tw(Sider)`
  h-full
  min-h-screen
  pb-1
`

const MenuItem = styled.div<any>`
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-bottom: ${props => (props.isBaseItem ? '5px' : '7px')};
  border-radius: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 5px;
  margin-right: ${props => (props.isCollapsed ? '6px' : '')};
  cursor: pointer;
  background-color: ${props => (props.isSelected ? 'rgb(238, 238, 238)' : '')};

  &:hover {
    background-color: rgb(238, 238, 238);
  }
`

const WorkspaceLabel = styled.div`
  font-size: 13px;
  font-weight: 500;
  margin-left: 13px;
  margin-bottom: 15px;
`

const SettingsLabel = styled.div`
  font-size: 15px;
  font-weight: 600;
  color: #646464;
`

const Navbar = (props: any) => {
  const { state, dispatch } = useContext(StateStore)
  const { push, pathname } = useRouter()
  const user = get(state, 'user')

  const collapsed = get(state, 'sidebar.collapsed', false)
  const [pipelineAnalyticsExpanded, setPipelineAnalyticsExpanded] = useState(
    pathname.includes('/pipeline-analytics'),
  )

  const isAutomationsEnabled = true //useFeatureFlagEnabled(FLAGS.Automations)
  const isMarketplaceEnabled = useFeatureFlagEnabled(FLAGS.Marketplace)

  const baseItems = [
    // {
    //   key: 'home',
    //   label: 'Home',
    //   icon: <HomeIcon width={16} height={16} />,
    //   path: '/home',
    //   onClick: () => push('/home'),
    // },
    {
      key: 'search',
      label: 'Search',
      icon: <MagnifyingGlassIcon width={16} height={16} />,
      path: '',
      onClick: () => {
        dispatch({
          type: 'TOGGLE_MODAL',
          payload: {
            key: 'commandMenu',
          },
        })
      },
    },
    {
      key: 'settings',
      label: 'Settings',
      icon: <Cog6ToothIcon width={16} height={16} />,
      path: '',
      onClick: () => {
        push('/teams')
        setTimeout(() => {
          dispatch({ type: 'TOGGLE_SIDEBAR', payload: false })
        }, 500)
      },
    },
  ]

  const opsItems = [
    {
      key: 'builder',
      label: 'Flow Builder',
      icon: <ShareIcon width={16} height={16} />,
      path: '/builder',
      onClick: () => push('/builder'),
    },
    {
      key: 'apex-editor',
      label: 'Apex Editor',
      icon: <CommandLineIcon width={16} height={16} />,
      path: '/apex-editor',
      onClick: () => push('/apex-editor'),
    },
    {
      key: 'activity-logs',
      label: 'Activity Logs',
      icon: <Square3Stack3DIcon width={16} height={16} />,
      path: '/activity-log',
      onClick: () => push('/activity-log'),
    },
    {
      key: 'flowinterpreter',
      label: 'Flow Error Inspector',
      icon: <WrenchIcon width={16} height={16} />,
      path: '/flowinterpreter',
      onClick: () => push('/flowinterpreter'),
    },
  ]

  if (user?.account?.has_documentation) {
    opsItems.push({
      key: 'documentation',
      label: 'Documentation',
      icon: <DocumentMagnifyingGlassIcon width={16} height={16} />,
      path: '/documentation/flow',
      onClick: () => push('/documentation/flow'),
    })
  }
  if (
    user?.account?.id !== '7c669019-d630-4fa7-be43-44a68beca24a' ||
    user?.is_super_admin
  ) {
    opsItems.unshift({
      key: 'reports',
      label: 'Reports & Analytics',
      icon: <ChartBarIcon width={16} height={16} />,
      path: '/reports',
      onClick: () => push('/reports'),
    })
  }

  if (isAutomationsEnabled) {
    opsItems.push({
      key: 'automations',
      label: 'Automations',
      icon: <ArrowPathIcon width={16} height={16} />,
      path: '/automations',
      onClick: () => push('/automations'),
    })
  }

  if (
    [
      '66466430-789c-4f5f-b24a-10020e78ffa5',
      '398545ac-831a-4d8c-a147-7b06eb3e43e0',
    ].includes(user?.account?.id)
  ) {
    opsItems.push({
      key: 'snapshots',
      label: 'Snapshots',
      icon: <EyeIcon width={16} height={16} />,
      path: '/snapshots',
      onClick: () => push('/snapshots'),
    })
  }

  if (isAutomationsEnabled) {
    opsItems.push({
      key: 'marketplace',
      label: 'Marketplace',
      icon: <SquaresPlusIcon width={16} height={16} />,
      path: '/marketplace',
      onClick: () => push('/marketplace'),
    })
  }

  const gtmItems = [
    {
      key: 'alerts',
      label: 'Alerts',
      icon: <BellAlertIcon width={16} height={16} />,
      path: '/alerts',
      onClick: () => {
        push('/alerts')
      },
      subItems: [] as any,
    },
    {
      key: 'assignments',
      label: 'Assignments',
      icon: <ClipboardIcon width={16} height={16} />,
      path: '/assignments/overview',
      onClick: () => push('/assignments/overview'),
      subItems: [] as any,
    },
    {
      key: 'pipeline-management',
      label: 'Pipeline Management',
      icon: <PencilSquareIcon width={16} height={16} />,
      path: '/pipeline-management',
      onClick: () => push('/pipeline-management'),
      subItems: [] as any,
    },
  ]

  const settingsItems = [
    {
      key: 'teams',
      label: 'Teams',
      icon: <UserCircleIcon width={16} height={16} />,
      path: '/teams',
      onClick: () => push('/teams'),
    },
    {
      key: 'forecasts',
      label: 'Forecasts',
      icon: <ArrowTrendingUpIcon width={16} height={16} />,
      path: '/settings/forecasts',
      onClick: () => push('/settings/forecasts'),
    },
    {
      key: 'instructions',
      label: 'Instructions',
      icon: <ChatBubbleBottomCenterTextIcon width={16} height={16} />,
      path: '/instructions',
      onClick: () => push('/instructions'),
    },
    {
      key: 'pipeline_management',
      label: 'Pipeline Management',
      icon: <PencilSquareIcon width={16} height={16} />,
      path: '/settings/pipeline-management',
      onClick: () => push('/settings/pipeline-management'),
    },
    {
      key: 'status_update',
      label: 'Synch Switch',
      icon: <PencilSquareIcon width={16} height={16} />,
      path: '/settings/status-update',
      onClick: () => push('/settings/status-update'),
    },
  ]

  const documentationItems = [
    {
      key: 'flows',
      label: 'Flows',
      icon: <ShareIcon width={16} height={16} />,
      path: '/documentation/flow',
      onClick: () => push('/documentation/flow'),
    },
    {
      key: 'apex-classes',
      label: 'Apex Classes',
      icon: <CommandLineIcon width={16} height={16} />,
      path: '/documentation/apex%20class',
      onClick: () => push('/documentation/apex%20class'),
    },
    {
      key: 'apex-triggers',
      label: 'Apex Triggers',
      icon: <ArrowPathIcon width={16} height={16} />,
      path: '/documentation/apex%20trigger',
      onClick: () => push('/documentation/apex%20trigger'),
    },
  ]

  if (
    [
      'b7c69189-ec2f-45c6-81fc-b31d7c8dcd6b',
      '75dde4ae-3baf-4a66-98c8-4ee99ac0aa94',
      '47036a2c-554e-4306-a868-b15d0fa4d7a4',
      '0191490c-21dd-4c7b-bc6f-8d1393171902',
      'f305f838-9ff3-4eb2-9b44-86dd1ea7c54b',
      'cbc06bba-d24d-440e-87d6-237d071eea70',
      'daf2097a-a7c6-48d4-84db-262e80e8129b',
      '398545ac-831a-4d8c-a147-7b06eb3e43e0',
      '7c669019-d630-4fa7-be43-44a68beca24a',
      '42bc440b-f61b-42ef-968b-be81f87d2b2e',
      '6373ccb8-1e46-49f9-8b44-df206610e5eb',
      '7f55138d-0460-4199-b07a-24bd124e28af',
      '759d8bab-a057-4963-8fe3-029b45eee508',
      'bc03104e-2b72-4f38-87b5-41f08886e563',
      '52d4adbb-6b28-49bb-ad63-9b84e7b412e4',
    ].includes(user?.account?.id) ||
    user?.email?.includes('@encamp.com') ||
    process.env.NODE_ENV === 'development' ||
    process.env.NEXT_PUBLIC_APP_ENV === 'staging'
  ) {
    if (user?.account?.id !== 'daf2097a-a7c6-48d4-84db-262e80e8129b') {
      gtmItems.push({
        key: 'forecastings',
        label: 'Forecasting',
        icon: <ArrowTrendingUpIcon width={16} height={16} />,
        path: '/forecastings',
        onClick: () => push('/forecastings'),
        subItems: [] as any,
      })
    }

    const scorecardsPath =
      user?.account?.id === '0191490c-21dd-4c7b-bc6f-8d1393171902'
        ? '/scorecards/coast'
        : user?.account?.id === 'daf2097a-a7c6-48d4-84db-262e80e8129b'
        ? '/scorecards/fundr'
        : '/scorecards'
    if (
      user?.account?.id !== 'f305f838-9ff3-4eb2-9b44-86dd1ea7c54b' &&
      user?.account?.id !== 'cbc06bba-d24d-440e-87d6-237d071eea70' &&
      user?.account?.id !== '398545ac-831a-4d8c-a147-7b06eb3e43e0' &&
      user?.account?.id !== '7c669019-d630-4fa7-be43-44a68beca24a' &&
      user?.account?.id !== '42bc440b-f61b-42ef-968b-be81f87d2b2e' &&
      user?.account?.id !== '6373ccb8-1e46-49f9-8b44-df206610e5eb' &&
      user?.account?.id !== '7f55138d-0460-4199-b07a-24bd124e28af'
    ) {
      gtmItems.push({
        key: 'scorecards',
        label: 'Scorecards',
        icon: <IdentificationIcon width={16} height={16} />,
        path: scorecardsPath,
        onClick: () => push(scorecardsPath),
        subItems: [] as any,
      })
    }
  }

  if (
    user?.account?.id === 'b7c69189-ec2f-45c6-81fc-b31d7c8dcd6b' ||
    user?.account?.id === '47036a2c-554e-4306-a868-b15d0fa4d7a4' ||
    user?.account?.id === 'b707374f-d952-4651-a3f5-2956c523fa40' ||
    user?.account?.id === '67ca6367-1dfc-40a7-9d6c-4a5390dde47a' ||
    user?.account?.id === '2f644be2-9bfe-4a34-8687-ebbd12ebbb45' ||
    user?.account?.id === '5974d8d9-5040-4f7f-b7c3-0c9d1eb8a217' ||
    user?.id === 'afac450f-fc62-4360-bd84-7e36cc483b9f' ||
    user?.email === 'connor@hausanalytics.com' ||
    user?.id === 'bbd85447-40c4-45b2-af62-bd349b0db3cc' ||
    user?.id === 'd7d5c563-6c75-4d5a-a27a-281297e97a69' ||
    user?.id === '266811c5-20ff-499f-89a2-8be41e6e4b1c' ||
    process.env.NODE_ENV === 'development' ||
    process.env.NEXT_PUBLIC_APP_ENV === 'staging'
  ) {
    gtmItems.push({
      key: 'pipeline-analytics',
      label: 'Pipeline Analytics',
      icon: <ChartBarSquareIcon width={16} height={16} />,
      path: '/pipeline-analytics',
      onClick: () => {
        setPipelineAnalyticsExpanded(!pipelineAnalyticsExpanded)
      },
      subItems: Object.entries(availableCharts)
        .filter(([slug, chart]) => chart.component)
        .map(([slug, chart]) => {
          return {
            key: slug,
            label: chart.name,
            path: `/pipeline-analytics/${slug}`,
            onClick: () => push(`/pipeline-analytics/${slug}`),
          }
        }),
    })
  }

  if (user?.plan === 'pipeline') {
    const pipelineSpecificItems = gtmItems.filter(item =>
      ['alerts', 'pipeline-management'].includes(item.key),
    )
    const otherItems = gtmItems.filter(
      item => !['alerts', 'pipeline-management'].includes(item.key),
    )
    gtmItems.length = 0
    gtmItems.push(...pipelineSpecificItems, ...otherItems)
  }

  const showSettingsNavbar =
    pathname.startsWith('/instructions') ||
    pathname.startsWith('/teams') ||
    pathname.startsWith('/settings/forecasts') ||
    pathname.startsWith('/settings/pipeline-management') ||
    pathname.startsWith('/settings/status-update')

  const showDocumentationNavbar =
    pathname.startsWith('/documentation') ||
    pathname.startsWith('/documentation/flow') ||
    pathname.startsWith('/documentation/apex%20class') ||
    pathname.startsWith('/documentation/apex%20trigger')

  const showViewDocumentationNavbar = pathname.startsWith('/documentation/view')

  const renderMenuItem = (item: any) => {
    const isDisabled =
      user?.plan === 'pipeline' &&
      item.key !== 'alerts' &&
      item.key !== 'pipeline-management'

    return (
      <Popover key={item.key} trigger='hover'>
        <PopoverTrigger>
          <div>
            <MenuItem
              onClick={!isDisabled ? item.onClick : undefined}
              isSelected={
                ['search', 'settings'].includes(item.key)
                  ? false
                  : pathname.includes('pipeline-analytics') &&
                    item.key === 'pipeline-analytics'
                  ? !pipelineAnalyticsExpanded
                  : pathname.includes(item.path)
              }
              isCollapsed={collapsed}
              isBaseItem={item.isBaseItem}
              style={{
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                opacity: isDisabled ? 0.5 : 1,
              }}
            >
              <div>{item.icon}</div>
              <div
                style={{
                  fontSize: '13px',
                  marginLeft: '10px',
                  display: collapsed ? 'none' : 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <span>{item.label}</span>
                {item.subItems?.length > 0 &&
                  !collapsed &&
                  (pipelineAnalyticsExpanded ? (
                    <ChevronDownIcon width={12} height={12} className='ml-2' />
                  ) : (
                    <ChevronRightIcon width={12} height={12} className='ml-2' />
                  ))}
              </div>
            </MenuItem>
            {pipelineAnalyticsExpanded && item.subItems && !collapsed && (
              <div
                style={{
                  // position: 'absolute',
                  // left: '100%',
                  // top: 0,
                  backgroundColor: 'rgb(248, 248, 248)',
                  borderRadius: '4px',
                  // boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  minWidth: '150px',
                }}
                // className='submenu'
              >
                {item.subItems.map((subItem: any) => {
                  console.log(
                    props.slug,
                    subItem.key,
                    String(props.slug) === String(subItem.key),
                  )
                  return (
                    <MenuItem
                      key={subItem.key}
                      onClick={!isDisabled ? subItem.onClick : undefined}
                      isSelected={String(props.slug) === String(subItem.key)}
                      style={{
                        cursor: isDisabled ? 'not-allowed' : 'pointer',
                        opacity: isDisabled ? 0.5 : 1,
                        marginLeft: '16px',
                        marginBottom: '2px',
                      }}
                    >
                      {subItem.icon && <div>{subItem.icon}</div>}
                      <div style={{ fontSize: '12px', marginLeft: '10px' }}>
                        {subItem.label}
                      </div>
                    </MenuItem>
                  )
                })}
              </div>
            )}
          </div>
        </PopoverTrigger>
        {isDisabled && (
          <PopoverContent>
            <PopoverBody>
              Purchase an enterprise plan for more features.{' '}
              <a
                href='https://calendly.com/danielsynch'
                target='_blank'
                rel='noopener noreferrer'
              >
                Upgrade here
              </a>
            </PopoverBody>
          </PopoverContent>
        )}
      </Popover>
    )
  }

  return (
    <StyledSider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{ border: 'none', marginTop: '5px' }}
    >
      {!showSettingsNavbar && !showDocumentationNavbar && <Header />}
      <Flex
        grow={1}
        direction={'column'}
        height={'calc(100% - 50px)'}
        backgroundColor={'rgb(248, 248, 248)'}
      >
        {showSettingsNavbar ? (
          <>
            <div
              style={{
                marginLeft: '13px',
                marginBottom: '15px',
                marginTop: '40px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                push('/reports')
              }}
            >
              <MdArrowBackIosNew
                style={{ marginRight: '10px', color: '#646464' }}
              />
              {!collapsed && <SettingsLabel>Settings</SettingsLabel>}
            </div>
            <div style={{ marginTop: '60px' }}>
              {settingsItems.map(item => renderMenuItem(item))}
            </div>
          </>
        ) : showDocumentationNavbar ? (
          <>
            <div
              style={{
                marginLeft: '13px',
                marginBottom: '15px',
                marginTop: '40px',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (showViewDocumentationNavbar) {
                  router.back()
                } else {
                  push('/reports')
                }
              }}
            >
              <MdArrowBackIosNew
                style={{ marginRight: '10px', color: '#646464' }}
              />
              {!collapsed && (
                <SettingsLabel>
                  {showViewDocumentationNavbar ? 'All' : 'Documentation'}
                </SettingsLabel>
              )}
            </div>
            <div style={{ marginTop: '60px' }}>
              {documentationItems.map(item => renderMenuItem(item))}
            </div>
          </>
        ) : (
          <>
            <div style={{ marginTop: '40px' }}>
              {baseItems.map(item => renderMenuItem(item))}
            </div>
            <div style={{ marginTop: '40px' }}>
              {!collapsed && <WorkspaceLabel>GTM</WorkspaceLabel>}
              {gtmItems.map(item => renderMenuItem(item))}
            </div>
            <div style={{ marginTop: '40px' }}>
              {!collapsed && <WorkspaceLabel>Ops</WorkspaceLabel>}
              {opsItems.map(item => renderMenuItem(item))}
            </div>
          </>
        )}
      </Flex>
    </StyledSider>
  )
}

export default Navbar
