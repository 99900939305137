'use client'

import '../styles/globals.css'
import 'react-datepicker/dist/react-datepicker.css'
import '../styles/commandMenu.scss'
import '../styles/antOverrides.scss'
import type { AppProps } from 'next/app'
import { useEffect, useState } from 'react'
import { DataProvider } from '../components/Provider'
import { StateStore } from '../components/Store'
import ChakraSystemProvider from '../components/ChakraSystemProvider'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import * as Sentry from '@sentry/react'
import Fallback from '../components/Fallback'

if (typeof window !== 'undefined') {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY ?? '', {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://app.posthog.com',
    loaded: posthog => {
      if (process.env.NODE_ENV === 'development') posthog.debug() // debug mode in development
    },
  })
}

const App = ({ Component, pageProps }: AppProps) => {
  const { Provider } = StateStore
  const [virtualDocument, setVirtualDocument] = useState<Window | undefined>(
    undefined,
  )

  useEffect(() => {
    if (window !== undefined) {
      setVirtualDocument(window)
    }
  }, [])

  return virtualDocument ? (
    <ChakraSystemProvider>
      <DataProvider {...{ Provider }}>
        <PostHogProvider client={posthog}>
          <Sentry.ErrorBoundary fallback={<Fallback />}>
            <Component {...pageProps} />
          </Sentry.ErrorBoundary>
        </PostHogProvider>
      </DataProvider>
    </ChakraSystemProvider>
  ) : (
    <div />
  )
}

export default App
