import AverageSalePrice from './AverageSalePrice'
import DaysToReachXStage from './DaysToReachXStage'
import DealsToReachXStage from './DealsToReachXStage'
import OpportunityWinRate from './OpportunityWinRate'
import OpenPipelineByStage from './OpenPipelineByStage'
import OpportunitiesCreatedByChannel from './OpportunitiesCreatedByChannel'
type AvailableChart = {
  name: string
  component: React.ComponentType<any> | null
}

function chartToDisplay(chartSlug: string, data: any, fiscalYearStartMonth: number) {
  if (chartSlug in availableCharts) {
    const ChartComponent = availableCharts[chartSlug].component
    if (ChartComponent) {
      return <ChartComponent data={data} fiscalYearStartMonth={fiscalYearStartMonth} />
    }
  }
  return <div>Chart component not found</div>
}

function chartNameFromSlug(chartSlug: string) {
  if (chartSlug in availableCharts) {
    return availableCharts[chartSlug].name
  }
  return 'Chart name not found'
}

const availableCharts: Record<string, AvailableChart> = {
  'deals-to-reach-x-stage': {
    name: 'Deals to Reach X Stage',
    component: DealsToReachXStage,
  },
  'days-to-reach-x-stage': {
    name: 'Days to Reach X Stage',
    component: DaysToReachXStage,
  },
  'opportunity-win-rate': {
    name: 'Opportunity Win Rate',
    component: OpportunityWinRate,
  },
  'average-sale-price': {
    name: 'Average Sale Price',
    component: AverageSalePrice,
  },
  'average-sales-cycle': {
    name: 'Average Sales Cycle',
    component: null,
  },
  'opportunities-created-by-channel': {
    name: 'Opportunities Created By Channel',
    component: OpportunitiesCreatedByChannel,
  },
  'open-pipeline-by-stage': {
    name: 'Open Pipeline by Stage',
    component: OpenPipelineByStage,
  },
  'new-pipeline-by-rep': {
    name: 'New Pipeline by Rep',
    component: null,
  },
  'total-closed-won-deals-and-closed-won-amount': {
    name: 'Total Closed Won Deals and Closed Won Amount',
    component: null,
  },
}

export { availableCharts, chartToDisplay, chartNameFromSlug }
