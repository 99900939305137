import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import styles from './pipelineAnalytics.module.css'
import { useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  CategoryScale,
  LinearScale,
  Chart as ChartJS,
  Legend,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js'
import { formatValueByType } from '../../lib/pageUtils/reportUtils/utils'
import { getColorByIndex } from '../../lib/colors'
import { cohortNameFromDate, renderCohortName } from './Utilities'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const COHORT_BUCKET = 'quarter' as const

type ProcessedData = {
  cohorts: string[]
  stageAmounts: Record<string, Record<string, number>> // stage -> cohort -> amount
}

function OpenPipelineByStage({
  data,
  fiscalYearStartMonth,
}: {
  data: any
  fiscalYearStartMonth: number
}) {
  const orderedDealStages: string[] =
    data?.deal_stages.map((stage: any) => stage.label) ?? []

  const orderedOpenStages = orderedDealStages.filter(
    stage => stage !== 'Closed Won' && stage !== 'Closed Lost',
  )

  const processedData = useMemo((): ProcessedData => {
    if (!data || !data.opportunities) {
      return {
        cohorts: [],
        stageAmounts: {},
      }
    }

    const cohortSet = new Set<string>()
    const stageAmounts: Record<string, Record<string, number>> = {}

    data.opportunities.forEach((opp: any) => {
      if (opp.StageName === 'Closed Won' || opp.StageName === 'Closed Lost') {
        return
      }

      const cohort = cohortNameFromDate(
        opp.CloseDate,
        COHORT_BUCKET,
        fiscalYearStartMonth,
      )
      const stage = opp.StageName
      const amount = parseFloat(opp.Amount) || 0

      cohortSet.add(cohort)

      if (!stageAmounts[stage]) {
        stageAmounts[stage] = {}
      }

      stageAmounts[stage][cohort] = (stageAmounts[stage][cohort] || 0) + amount
    })

    const cohorts = Array.from(cohortSet).sort()

    return {
      cohorts,
      stageAmounts,
    }
  }, [data])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
  )

  const chartData = {
    labels: processedData.cohorts.map(cohort =>
      renderCohortName(cohort, COHORT_BUCKET),
    ),
    datasets: orderedOpenStages.map((stage, index) => ({
      label: stage,
      data: processedData.cohorts.map(
        cohort => processedData.stageAmounts[stage]?.[cohort] || 0,
      ),
      backgroundColor: getColorByIndex(index),
    })),
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw || 0
            return `${context.dataset.label}: ${formatValueByType(
              value,
              'currency',
            )}`
          },
        },
      },
      datalabels: {
        display: (context: any) => {
          return context.datasetIndex === orderedOpenStages.length - 1
        },
        formatter: (value: any, context: any) => {
          const cohortIndex = context.dataIndex
          const total = orderedOpenStages.reduce((sum, stage) => {
            return (
              sum +
              (processedData.stageAmounts[stage]?.[
                processedData.cohorts[cohortIndex]
              ] || 0)
            )
          }, 0)
          return formatValueByType(total, 'currency')
        },
        anchor: 'end' as const,
        align: 'top' as const,
        offset: 5,
        font: {
          size: 14,
          weight: 'bold' as const,
        },
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          callback: (value: any) => formatValueByType(value, 'currency'),
        },
      },
    },
  }

  return (
    <>
      <div
        style={{
          fontSize: '18px',
          fontWeight: '600',
          marginLeft: '10px',
          marginBottom: '24px',
        }}
      >
        Open Pipeline by Stage
      </div>
      <div style={{ height: '50vh' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
      <div style={{ width: '100%', overflowX: 'scroll', marginTop: '48px' }}>
        <Table className={styles.fixedFirstColumn} variant='simple' size='sm'>
          <Thead>
            <Tr>
              <Th>Cohort</Th>
              {orderedOpenStages.map(stage => (
                <Th key={stage}>{stage}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {(() => {
              const totals: Record<string, number> = {}

              // Render cohort rows while accumulating totals
              const rows = processedData.cohorts.map((cohort, index) => {
                const currentFiscalYear = cohort.split('-')[0]
                const isNewFiscalYear =
                  index === 0 ||
                  currentFiscalYear !==
                    processedData.cohorts[index - 1].split('-')[0]
                const rowClass = isNewFiscalYear
                  ? styles['fiscal-year-boundary']
                  : ''

                return (
                  <Tr key={cohort} className={rowClass}>
                    <Td>{renderCohortName(cohort, COHORT_BUCKET)}</Td>
                    {orderedOpenStages.map(stage => {
                      const amount =
                        processedData.stageAmounts[stage]?.[cohort] || 0
                      totals[stage] = (totals[stage] || 0) + amount
                      return (
                        <Td key={stage}>
                          {formatValueByType(amount, 'currency')}
                        </Td>
                      )
                    })}
                  </Tr>
                )
              })

              rows.push(
                <Tr key='cumulative' className={styles['fiscal-year-boundary']}>
                  <Td>
                    <strong>Cumulative</strong>
                  </Td>
                  {orderedOpenStages.map(stage => (
                    <Td key={stage}>
                      <strong>
                        {formatValueByType(totals[stage], 'currency')}
                      </strong>
                    </Td>
                  ))}
                </Tr>,
              )

              return rows
            })()}
          </Tbody>
        </Table>
      </div>
    </>
  )
}

export default OpenPipelineByStage
