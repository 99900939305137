import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import styles from './pipelineAnalytics.module.css'
import { useMemo, useState } from 'react'
import {
  groupHistoryByOpportunity,
  addDaysToStageToOpportunities,
  groupDataForTable,
  mapTableDataToAverageDaysToStage,
} from './DaysToReachXStageLogic'
import { renderCohortName } from './Utilities'
import { Line } from 'react-chartjs-2'
import { PointElement, LineElement, Chart as ChartJS } from 'chart.js'
import { getColorByIndex } from '../../lib/colors'

const COHORT_BUCKET = 'month' as const

function DaysToReachXStage({ data }: { data: any }) {
  const orderedDealStages: string[] =
    data?.deal_stages.map((stage: any) => stage.label) ?? []

  const { cohortsFound, tableData } = useMemo(() => {
    if (!data || !data.opportunities || !data.opportunity_history) {
      return {
        cohortsFound: [],
        tableData: {},
      }
    }

    const changesPerOpportunity = groupHistoryByOpportunity(
      data.opportunity_history,
    )

    const opportunitiesWithDaysToStage = addDaysToStageToOpportunities(
      data.opportunities,
      changesPerOpportunity,
      orderedDealStages,
    )
    const { tableData, cohortsFound } = groupDataForTable(
      opportunitiesWithDaysToStage,
      orderedDealStages,
    )

    const averageDaysToStage = mapTableDataToAverageDaysToStage(tableData)

    return {
      cohortsFound: Array.from(cohortsFound).sort(),
      tableData: averageDaysToStage,
    }
  }, [data])

  ChartJS.register(PointElement, LineElement)

  const colors = Array.from(cohortsFound).reduce(
    (acc: Record<string, string>, cohort, index) => {
      acc[cohort] = getColorByIndex(index)
      return acc
    },
    {},
  )

  const verticalHoverLine = {
    id: 'verticalHoverLine',
    beforeDatasetsDraw(chart: any, args: any, plugins: any) {
      const {
        ctx,
        chartArea: { top, bottom, height },
      } = chart

      ctx.save()

      chart.getDatasetMeta(0).data.forEach((point: any, index: any) => {
        if (point.active) {
          ctx.beginPath()
          ctx.strokeStyle = 'grey'
          ctx.moveTo(point.x, top)
          ctx.lineTo(point.x, bottom)
          ctx.stroke()
        }
      })
      ctx.restore()
    },
  }

  const lineChartData = {
    labels: orderedDealStages,
    datasets: Array.from(cohortsFound).map(cohort => ({
      label: renderCohortName(cohort, COHORT_BUCKET),
      backgroundColor: colors[cohort],
      borderColor: colors[cohort],
      data: orderedDealStages.map(dealStage => {
        return (
          (tableData && tableData[dealStage] && tableData[dealStage][cohort]) ||
          0
        )
      }),
    })),
  }
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    plugins: {
      legend: {
        position: 'top' as const,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      y: {
        title: {
          display: true,
          text: 'Avg. Days to Reach',
        },
      },
      x: {
        grid: {
          display: false,
        },
      },
    },
  }

  return (
    <>
      <div
        style={{
          fontSize: '18px',
          fontWeight: '600',
          marginLeft: '10px',
          marginBottom: '24px',
        }}
      >
        Days to Reach X Stage
      </div>

      <div style={{ height: '50vh' }}>
        <Line
          data={lineChartData}
          options={lineChartOptions}
          plugins={[verticalHoverLine]}
        />
      </div>

      <div style={{ width: '100%', overflowX: 'scroll', marginTop: '48px' }}>
        <Table className={styles.fixedFirstColumn} variant='simple' size='sm'>
          <Thead>
            <Tr>
              <Th>Cohort</Th>
              {orderedDealStages.map(dealStage => (
                <Th
                  sx={{
                    textTransform: 'none',
                    letterSpacing: 'none',
                    color: '#71717a',
                    fontWeight: '500',
                  }}
                  key={dealStage}
                >
                  {dealStage}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {Array.from(cohortsFound).map(cohort => {
              // const averageDays = tableData[dealStage]
              return (
                <Tr key={cohort}>
                  <Td
                    style={{
                      borderBottom: 'none',
                      fontSize: '13px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ marginLeft: '2px' }}>
                      <span
                        style={{
                          display: 'inline-block',
                          width: '10px',
                          height: '10px',
                          backgroundColor: colors[cohort],
                          marginRight: '8px',
                        }}
                      ></span>
                      {renderCohortName(cohort, COHORT_BUCKET)}
                    </span>
                  </Td>
                  {orderedDealStages.map(dealStage => (
                    <Td key={dealStage}>
                      {tableData &&
                      tableData[dealStage] &&
                      tableData[dealStage][cohort]
                        ? tableData[dealStage][cohort].toFixed(1)
                        : '-'}
                    </Td>
                  ))}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </div>
    </>
  )
}
export default DaysToReachXStage
