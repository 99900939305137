import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import styles from './pipelineAnalytics.module.css'
import { useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  CategoryScale,
  LinearScale,
  Chart as ChartJS,
  Legend,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js'
import { cohortNameFromDate, renderCohortName } from './Utilities'
import { getColorByIndex } from '../../lib/colors'

const COHORT_BUCKET = 'month' as const

function DealsToReachXStage({
  data,
  fiscalYearStartMonth,
}: {
  data: any
  fiscalYearStartMonth: number
}) {
  const orderedDealStages: string[] =
    data?.deal_stages.map((stage: any) => stage.label) ?? []

  const dealStageIndex: Record<string, number> = data?.deal_stages
    ? orderedDealStages.reduce(
        (acc: Record<string, number>, stage: string, index: number) => {
          acc[stage] = index
          return acc
        },
        {},
      )
    : {}

  const { cohortsFound, rowsDeals, cumulativeRowsDeals } = useMemo(() => {
    if (!data || !data.opportunities)
      return {
        cohortsFound: [],
        rowsDeals: {},
        cumulativeRowsDeals: {},
      }

    // Process opportunities into cohort and deal stage counts
    const rowsDeals: Record<string, Record<string, number>> = {}
    const cohortsFound = new Set<string>([])
    data.opportunities.forEach((opportunity: any) => {
      const dealStage = opportunity.StageName
      if (dealStage === 'Closed Lost') return
      const cohort = cohortNameFromDate(
        opportunity.CreatedDate,
        COHORT_BUCKET,
        fiscalYearStartMonth,
      )
      if (!rowsDeals[dealStage]) {
        rowsDeals[dealStage] = {}
      }
      rowsDeals[dealStage][cohort] = (rowsDeals[dealStage][cohort] || 0) + 1
      cohortsFound.add(cohort)
    })

    const sortedCohorts: Array<string> = Array.from(cohortsFound).sort()

    // Count cumulative totals for display in table
    const cumulativeRowsDeals: Record<string, Record<string, number>> = {}
    let previousRow: Record<string, number> = {}
    orderedDealStages
      .slice()
      .reverse()
      .forEach(dealStage => {
        const currentRow = rowsDeals[dealStage]
        if (!(dealStage in cumulativeRowsDeals)) {
          cumulativeRowsDeals[dealStage] = {}
        }
        Array.from(cohortsFound).forEach(cohort => {
          cumulativeRowsDeals[dealStage][cohort] =
            (previousRow[cohort] || 0) +
            ((currentRow && currentRow[cohort]) || 0)
        })
        previousRow = cumulativeRowsDeals[dealStage]
      })

    return { cohortsFound: sortedCohorts, rowsDeals, cumulativeRowsDeals }
  }, [data])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  )

  const chartData = {
    labels: Array.from(cohortsFound).map(cohort =>
      renderCohortName(cohort, COHORT_BUCKET),
    ),
    datasets: orderedDealStages.map(dealStage => ({
      label: dealStage,
      data: Array.from(cohortsFound).map(cohort => {
        return (
          (rowsDeals && rowsDeals[dealStage] && rowsDeals[dealStage][cohort]) ||
          0
        )
      }),
      backgroundColor: getColorByIndex(dealStageIndex[dealStage]),
    })),
  }
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          drawOnChartArea: false,
          drawTicks: true,
        },
        ticks: {
          stepSize: 1
        }
      },
    },
  }

  return (
    <>
      <div
        style={{
          fontSize: '18px',
          fontWeight: '600',
          marginLeft: '10px',
          marginBottom: '24px',
        }}
      >
        Deals to Reach X Stage
      </div>
      <div style={{ height: '50vh' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>

      <div style={{ width: '100%', overflowX: 'scroll', marginTop: '48px' }}>
        <Table className={styles.fixedFirstColumn} variant='simple' size='sm'>
          <Thead>
            <Tr>
              <Th></Th>
              <Th textAlign='center' colSpan={orderedDealStages.length}>
                Reached Stage
              </Th>
            </Tr>
            <Tr>
              <Th>Cohort</Th>
              {orderedDealStages.map((dealStage, index) => (
                <Th
                  sx={{
                    textTransform: 'none',
                    letterSpacing: 'none',
                    color: '#71717a',
                    fontWeight: '500',
                    whiteSpace: 'nowrap',
                  }}
                  key={dealStage}
                >
                  <span
                    style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      backgroundColor: getColorByIndex(index),
                      marginRight: '8px',
                    }}
                  ></span>
                  {dealStage}
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody>
            {Array.from(cohortsFound).map(cohort => {
              return (
                <Tr key={cohort}>
                  <Td
                    style={{
                      borderBottom: 'none',
                      fontSize: '13px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span style={{ marginLeft: '2px' }}>
                      {renderCohortName(cohort, COHORT_BUCKET)}
                    </span>
                  </Td>
                  {orderedDealStages.map(dealStage => (
                    <Td key={dealStage} textAlign='center'>
                      {cumulativeRowsDeals[dealStage][cohort] || 0}
                    </Td>
                  ))}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
      </div>
    </>
  )
}
export default DealsToReachXStage
