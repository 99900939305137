import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import styles from './pipelineAnalytics.module.css'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  CategoryScale,
  LinearScale,
  Chart as ChartJS,
  Legend,
  BarElement,
  Title,
  Tooltip,
} from 'chart.js'
import { cohortNameFromDate, renderCohortName } from './Utilities'
import { Opportunity } from './Types'
import { getColorByIndex } from '../../lib/colors'

// Move registration outside component
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

type ProcessedData = {
  cohorts: string[]
  channelCounts: Record<string, Record<string, number>>
}

const COHORT_BUCKET = 'month' as const

function OpportunitiesCreatedByChannel({ data }: { data: any }) {
  const processedData = useMemo((): ProcessedData => {
    if (!data?.opportunities) {
      return {
        cohorts: [],
        channelCounts: {},
      }
    }

    const cohortSet = new Set<string>()
    const channelCounts: Record<string, Record<string, number>> = {}

    data.opportunities.forEach((opp: Opportunity) => {
      const cohort = cohortNameFromDate(opp.CreatedDate, COHORT_BUCKET)
      const channel = opp.LeadSource || 'Unknown'

      cohortSet.add(cohort)

      if (!channelCounts[channel]) {
        channelCounts[channel] = {}
      }

      channelCounts[channel][cohort] = (channelCounts[channel][cohort] || 0) + 1
    })

    return {
      cohorts: Array.from(cohortSet).sort(),
      channelCounts,
    }
  }, [data])

  const sortedChannels = Object.keys(processedData.channelCounts).sort()

  const chartData = {
    labels: processedData.cohorts.map(cohort =>
      renderCohortName(cohort, COHORT_BUCKET),
    ),
    datasets: sortedChannels.map((channel, index) => ({
      label: channel,
      data: processedData.cohorts.map(
        cohort => processedData.channelCounts[channel][cohort] || 0,
      ),
      backgroundColor: getColorByIndex(index),
    })),
  }

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
          pointStyle: 'rect',
        },
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const value = context.raw || 0
            const opportunityText =
              value === 1 ? 'opportunity' : 'opportunities'
            return `${context.dataset.label}: ${value} ${opportunityText}`
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
  }

  return (
    <>
      <div
        style={{
          fontSize: '18px',
          fontWeight: '600',
          marginLeft: '10px',
          marginBottom: '24px',
        }}
      >
        Opportunities Created by Channel
      </div>

      <div style={{ height: '50vh' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>

      <div style={{ width: '100%', overflowX: 'scroll', marginTop: '48px' }}>
        <Table className={styles.fixedFirstColumn} variant='simple' size='sm'>
          <Thead>
            <Tr>
              <Th>Cohort</Th>
              {sortedChannels.map((channel, index) => (
                <Th key={channel}>
                  <span
                    style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      backgroundColor: getColorByIndex(index),
                      marginRight: '8px',
                    }}
                  ></span>
                  {channel}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {processedData.cohorts.map(cohort => (
              <Tr key={cohort}>
                <Td>{renderCohortName(cohort, COHORT_BUCKET)}</Td>
                {sortedChannels.map(channel => (
                  <Td key={channel}>
                    {processedData.channelCounts[channel][cohort] || 0}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </>
  )
}

export default OpportunitiesCreatedByChannel
