import { Opportunity, OpportunityHistory } from './Types'
import { cohortNameFromOpp, daysBetweenDates } from './Utilities'

type OpportunityWithDaysToStage = Opportunity & {
  DaysToStage: Record<string, number | null>
}

export function groupHistoryByOpportunity(history: OpportunityHistory[]) {
  const changesPerOpportunity: Record<string, Record<string, string>> = {}

  history.sort(
    (a, b) =>
      new Date(a.CreatedDate).getTime() - new Date(b.CreatedDate).getTime(),
  )

  const historyPerOpportunity: Record<string, OpportunityHistory[]> = {}
  history.forEach((history: OpportunityHistory) => {
    const opportunityId = history.OpportunityId
    if (!historyPerOpportunity[opportunityId]) {
      historyPerOpportunity[opportunityId] = []
    }
    historyPerOpportunity[opportunityId].push(history)
  })

  // for each opportunity, iterate through sorted history and find changes in stage
  Object.keys(historyPerOpportunity).forEach(opportunityId => {
    const history = historyPerOpportunity[opportunityId]
    let previousStage: string | null = null
    history.forEach((history: OpportunityHistory) => {
      const stage = history.StageName
      const date = history.CreatedDate
      if (stage !== previousStage) {
        if (!changesPerOpportunity[opportunityId]) {
          changesPerOpportunity[opportunityId] = {}
        }
        changesPerOpportunity[opportunityId][stage] = date
        previousStage = stage
      }
    })
  })
  return changesPerOpportunity
}

export function calculateStageDaysForOpportunity(
  opportunity: any,
  changesPerOpportunity: Record<string, Record<string, string>>,
  orderedDealStages: string[],
) {
  const createdDate = opportunity.CreatedDate
  const stageToDate = changesPerOpportunity[opportunity.Id]
  let stageToDays: Record<string, number | null> = {}
  let previousDate: string | null = null
  // Unhandled edgecase: This might need to do something where the everything after the Opportunity's current stage is set to null
  orderedDealStages
    .slice()
    .reverse()
    .forEach(stage => {
      const date = stageToDate?.[stage]
      if (date) {
        stageToDays[stage] = daysBetweenDates(createdDate, date)
        previousDate = date
      } else if (previousDate) {
        // This implementation accounts for the fact that an Opportunity might
        // skip stages and thus not have a history item for some stages. In
        // these cases, it behaves as if the Opportunity reached the missing
        // intermediate stages on the same date as the later stage.
        stageToDays[stage] = daysBetweenDates(createdDate, previousDate)
      } else {
        stageToDays[stage] = null
      }
    })
  return stageToDays
}

export function addDaysToStageToOpportunities(
  opportunities: Opportunity[],
  changesPerOpportunity: Record<string, Record<string, string>>,
  orderedDealStages: string[],
) {
  const opportunitiesWithDaysToStage = opportunities.map(opportunity => {
    const daysToStage = calculateStageDaysForOpportunity(
      opportunity,
      changesPerOpportunity,
      orderedDealStages,
    )
    return { ...opportunity, DaysToStage: daysToStage }
  })
  return opportunitiesWithDaysToStage
}

export function groupDataForTable(
  opportunities: OpportunityWithDaysToStage[],
  orderedDealStages: string[],
) {
  const tableData: Record<
    string,
    Record<string, OpportunityWithDaysToStage[]>
  > = {}
  orderedDealStages.forEach(stage => {
    tableData[stage] = {}
  })
  const cohortsFound = new Set<string>()
  opportunities.forEach(opportunity => {
    const cohort = cohortNameFromOpp(opportunity)
    cohortsFound.add(cohort)
    orderedDealStages.forEach(stage => {
      if (!tableData[stage][cohort]) {
        tableData[stage][cohort] = []
      }
      tableData[stage][cohort].push(opportunity)
    })
  })
  return { tableData, cohortsFound }
}

export function averageDaysToStage(
  opportunities: OpportunityWithDaysToStage[],
  stage: string,
) {
  const includedOpportunities = opportunities.filter(
    opportunity => opportunity.DaysToStage[stage] !== null,
  )
  const totalDays = includedOpportunities.reduce(
    (sum, opportunity) => sum + (opportunity.DaysToStage[stage] ?? 0),
    0,
  )
  return totalDays / includedOpportunities.length
}

export function mapTableDataToAverageDaysToStage(
  tableData: Record<string, Record<string, OpportunityWithDaysToStage[]>>,
) {
  const result: Record<string, Record<string, number>> = {}

  for (const [stage, innerDict] of Object.entries(tableData)) {
    const innerResult: Record<string, number> = {}

    for (const [cohort, opportunities] of Object.entries(innerDict)) {
      innerResult[cohort] = averageDaysToStage(opportunities, stage)
    }

    result[stage] = innerResult
  }

  return result
}
