import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import styles from './pipelineAnalytics.module.css'
import { useMemo, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import {
  CategoryScale,
  LinearScale,
  Chart as ChartJS,
  Legend,
  BarElement,
  Title,
  Tooltip,
  PointElement,
} from 'chart.js'
import { formatValueByType } from '../../lib/pageUtils/reportUtils/utils'
import { cohortNameFromDate, renderCohortName } from './Utilities'
import { getColorByIndex } from '../../lib/colors'

type CohortData = {
  sum: number
  count: number
}

const calculateAverages = (cohortTotals: Record<string, CohortData>) => {
  return Object.entries(cohortTotals).reduce(
    (acc, [cohort, { sum, count }]) => {
      acc[cohort] = count > 0 ? sum / count : 0
      return acc
    },
    {} as Record<string, number>,
  )
}

const getChartData = (
  cohortsFound: string[],
  cohortAverages: Record<string, number>,
) => {
  const datasets = [
    {
      label: 'Average Sale Price',
      data: cohortsFound.map(cohort => cohortAverages[cohort] || 0),
      backgroundColor: getColorByIndex(0),
    },
  ]

  const labels = cohortsFound.map(cohort => renderCohortName(cohort, 'month'))

  return { labels, datasets }
}

const COHORT_BUCKET = 'month' as const

function AverageSalePrice({
  data,
  fiscalYearStartMonth,
}: {
  data: any
  fiscalYearStartMonth: number
}) {
  const {
    cohortsFound,
    cohortAverages,
  }: {
    cohortsFound: string[]
    cohortAverages: Record<string, number>
  } = useMemo(() => {
    if (!data?.opportunities) {
      return { cohortsFound: [], cohortAverages: {} }
    }

    const totals: Record<string, CohortData> = {}
    data.opportunities.forEach((opportunity: any) => {
      if (opportunity.StageName !== 'Closed Won') return

      const cohort = cohortNameFromDate(
        opportunity.CreatedDate,
        COHORT_BUCKET,
        fiscalYearStartMonth,
      )
      if (!totals[cohort]) {
        totals[cohort] = { sum: 0, count: 0 }
      }
      totals[cohort].sum += opportunity.Amount || 0
      totals[cohort].count += 1
    })

    return {
      cohortsFound: Object.keys(totals).sort(),
      cohortAverages: calculateAverages(totals),
    }
  }, [data])

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
  )

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            return formatValueByType(context.raw, 'currency').toString()
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          drawOnChartArea: false,
          drawTicks: true,
        },
        ticks: {
          callback: function (this: any, tickValue: number | string) {
            return formatValueByType(Number(tickValue), 'currency').toString()
          },
        },
      },
    },
  }

  const chartData = getChartData(cohortsFound, cohortAverages)

  return (
    <>
      <div
        style={{
          fontSize: '18px',
          fontWeight: '600',
          marginLeft: '10px',
          marginBottom: '24px',
        }}
      >
        Average Sale Price
      </div>
      <div style={{ height: '50vh' }}>
        <Bar data={chartData} options={chartOptions} />
      </div>
      <div style={{ width: '100%', overflowX: 'scroll', marginTop: '48px' }}>
        <Table className={styles.fixedFirstColumn} variant='simple' size='sm'>
          <Thead>
            <Tr>
              <Th>Cohort</Th>
              <Th>Amount</Th>
            </Tr>
          </Thead>
          <Tbody>
            {cohortsFound.map(cohort => (
              <Tr key={cohort}>
                <Td>{renderCohortName(cohort, COHORT_BUCKET)}</Td>
                <Td>
                  {formatValueByType(cohortAverages[cohort] || 0, 'currency')}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </>
  )
}

export default AverageSalePrice
